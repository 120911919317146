// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-en-js": () => import("./../src/templates/index-page-en.js" /* webpackChunkName: "component---src-templates-index-page-en-js" */),
  "component---src-templates-partners-page-js": () => import("./../src/templates/partners-page.js" /* webpackChunkName: "component---src-templates-partners-page-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-about-page-en-js": () => import("./../src/templates/about-page-en.js" /* webpackChunkName: "component---src-templates-about-page-en-js" */),
  "component---src-templates-contact-page-en-js": () => import("./../src/templates/contact-page-en.js" /* webpackChunkName: "component---src-templates-contact-page-en-js" */),
  "component---src-templates-partners-page-en-js": () => import("./../src/templates/partners-page-en.js" /* webpackChunkName: "component---src-templates-partners-page-en-js" */),
  "component---src-templates-project-en-js": () => import("./../src/templates/project-en.js" /* webpackChunkName: "component---src-templates-project-en-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-projects-index-js": () => import("./../src/pages/en/projects/index.js" /* webpackChunkName: "component---src-pages-en-projects-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

